.phoneInput input {
    display: none;
}

.phoneInput .PhoneInputCountrySelect {
    display: flex;
    align-items: center;
    width: 85px;
}

.phoneInput .PhoneInputCountryIcon {
    border-radius: 50%;
    overflow: hidden;
    width: 23px;
    height: 21px;
    border: 0px solid gray;
}

.phoneInput .PhoneInputCountryIcon img {
    object-fit: cover;
}

.phoneInput .PhoneInputCountrySelectArrow {
    display: none;
}
